// bs4 navbars require .navbar-expand[-sm|-md|-lg|-xl], but bs3 navbars
// don't have them. This selector matches .navbar without .navbar-expand
// and defaults it to .navbar-expand-sm.
.navbar:not(.navbar-expand):not(.navbar-expand-sm):not(.navbar-expand-md):not(.navbar-expand-lg):not(.navbar-expand-xl) {
  @extend .navbar-expand-sm;
}

.navbar:not(.fixed-bottom):not(.navbar-fixed-bottom) {
  margin-bottom: $navbar-margin-bottom;
}

// Map BS3 navbar positioning to general utilities
.navbar-fixed-top {
  @extend .fixed-top;
}
.navbar-fixed-bottom {
  @extend .fixed-bottom;
}
.navbar-sticky-top {
  @extend .sticky-top;
}

ul.nav.navbar-nav {
  flex: 1;
  -webkit-flex: 1;
  &.navbar-right {
    flex: unset;
    -webkit-flex: unset;
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
  }
}

ul.nav.navbar-nav>li:not(.dropdown) {
  @extend .nav-item;
}
ul.nav.navbar-nav>li>a {
  @extend .nav-link;
}

// BS3 .navbar-default -> BS4 .navbar-light
.navbar.navbar-default {
  // Sets a variety of fg colors which are configurable via $navbar-light-* options
  @extend .navbar-light;
  // To make it easier for end users to set the navbar bg color,
  // we've introduced this variable
  background-color: $navbar-light-bg !important;
}

// BS3 .navbar-inverse -> BS4 .navbar-dark
.navbar.navbar-inverse {
  // Sets a variety of fg colors which are configurable via $navbar-dark-* options
  @extend .navbar-dark;
  // See comments above for $navbar-light-bg
  background-color: $navbar-dark-bg !important;
}

// Implement bs3 navbar toggler; used in Rmd websites, i.e.
// https://github.com/rstudio/rmarkdown-website/blob/453e1802b32b5baf1c8a67f80947adcc53e49b7f/_navbar.html
.navbar-toggle {
  @extend .navbar-toggler;
}
.navbar-toggle {
  > .icon-bar {
    display: none;
    &:last-child {
      @extend .navbar-toggler-icon;
    }
  }
}

// Make sure navbar-toggle floats right when navbar is collapsed
// https://github.com/rstudio/bslib/issues/289
// In BS4, media-breakpoint-down() does the _next_ breakpoint (xs->sm)
// In BS5+, it takes the breakpoint (sm) verbatim
@include media-breakpoint-down(if($bootstrap-version==4, xs, sm)) {
  .navbar-header {
    width: 100%;
    .navbar-toggle {
      float: right;
    }
  }
}
