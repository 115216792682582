@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_functions.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/sass-utils/color-contrast.scss";
$color-contrast-warnings: true !default;



@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/bs3compat/_defaults.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_variables.scss";
$bootstrap-version: 5;
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_mixins.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/bs3compat/_declarations.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_utilities.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_root.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_reboot.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_type.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_images.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_containers.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_grid.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_tables.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_forms.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_buttons.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_transitions.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_dropdown.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_button-group.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_nav.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_navbar.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_card.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_accordion.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_breadcrumb.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_pagination.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_badge.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_alert.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_progress.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_list-group.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_close.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_toasts.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_modal.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_tooltip.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_popover.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_carousel.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_spinners.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_offcanvas.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_placeholders.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/_helpers.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/lib/bs5/scss/utilities/_api.scss";
.table th[align=left] { text-align: left; }
.table th[align=right] { text-align: right; }
.table th[align=center] { text-align: center; }
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/bs3compat/_rules.scss";
@import "/Library/Frameworks/R.framework/Versions/4.1/Resources/library/bslib/nav-spacer/nav-spacer.scss";