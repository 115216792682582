// BS3 -> BS4 saw a removal of margin-top on headers,
// which is a pretty jarring change, expecially for Rmd
// https://github.com/twbs/bootstrap/blob/f17f882d/dist/css/bootstrap.css#L1219-L1250
// https://github.com/twbs/bootstrap/blob/4de4874e/scss/_reboot.scss#L82-L89
h1, h2, h3 {
  margin-top: 1.5 * $spacer;
}
h4, h5, h6 {
  margin-top: $spacer;
}
